@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
/* Styles define */
/* Styles define */
:root {
    --main: #10A0C1;
    --main1: #FFAD2A;
    --green: #54B66F;
    --red: #DF3A4D;
    --orange: #FF832B;
    --cyan: #33B1FF;
    --grey1: #0A0A26;
    --grey2: #47475C;
    --grey3: #848893;
    --grey4: #C2C2C9;
    --grey5: #E7E7E9;
    --grey6: #F9F9FA;
    --white: #FFFFFF;
  
    /* Font sizes */
    --font-size_h1: 32px;
    --line-height_h1: 40px;
    --fontsize_h2: 24px;
    --lineheight_h2: 32px;
    --font-size_h3: 20px;
    --line-height_h3: 28px;
    --font-size_h4: 18px;
    --line-height_h4: 20px;
    --font-size_h5: 17px;
    --line-height_h5: 24px;
    --font-size_s1: 26px;
    --line-height_s1: auto;
    --font-size_s2: 22px;
    --line-height_s2: 32px;
    --font-size_s3: 26px;
    --line-height_s3: auto;
    --font-size_s4: 14px;
    --line-height_s4: 24px;
    --font-size_body: 15px;
    --line-height_body: 20px;
    --font-size_footnote: 13px;
    --line-height_footnote: 18px;
    --font-size_bottomnote: 15px;
    --line-height_bottomnote: 20px;

    
    /* General font sizes */
    --size-8: 8px;
    --size-9: 9px;
    --size-10: 10px;
    --size-11: 11px;
    --size-12: 12px;
    --size-13: 13px;
    --size-14: 14px;
    --size-15: 15px;
    --size-16: 16px;
    --size-17: 17px;
    --size-18: 18px;
    --size-19: 19px;
    --size-20: 20px;
    --size-21: 21px;
    --size-22: 22px;
    --size-23: 23px;
    --size-24: 24px;
    --size-25: 25px;
    --size-26: 26px;
    --size-27: 27px;
    --size-28: 28px;
    --size-29: 29px;
    --size-30: 30px;
    --size-32: 32px;
    --size-36: 36x;
    --size-38: 38px;
    --size-40: 40px;
    --size-42: 42px;
    --size-46: 46px;
    --size-48: 48px;
    --size-50: 50px;
  }
/* disable default outlines in buttons */
* {
    outline: none !important;
}

/* ----- BASIC HELPER CLASSES TO SET COLORS TO COMPONENTS ----- */

/* BACKGROUND COLORS */
.bg-grey1 {
    background-color: var(--grey1);
}

.bg-grey2 {
    background-color: var(--grey2);
}

.bg-grey3 {
    background-color: var(--grey3);
}

.bg-grey4 {
    background-color: var(--grey4);
}

.bg-grey5 {
    background-color: var(--grey5);
}

.bg-grey6 {
    background-color: var(--grey6);
}

.bg-white {
    background-color: var(--white);
}

.bg-green {
    background-color: var(--green);
}

.bg-red {
    background-color: var(--red);
}

.bg-main {
    background-color: var(--main);
}

.bg-main1 {
    background-color: var(--main1);
}

.bg-main2 {
    background-color: var(--main2);
}

/* TEXT COLORS */

.text-grey1 {
    color: var(--grey1);
}

.text-grey2 {
    color: var(--grey2);
}

.text-grey3 {
    color: var(--grey3);
}

.text-grey4 {
    color: var(--grey4);
}

.text-grey5 {
    color: var(--grey5);
}

.text-grey6 {
    color: var(--grey6);
}

.text-white {
    color: var(--white);
}

.text-green {
    color: var(--green);
}

.text-red {
    color: var(--red);
}

.text-main {
    color: var(--main) !important;
}

.text-main1 {
    color: var(--main1);
}

.text-main2 {
    color: var(--main2);
}

/* ----- END OF BASIC HELPER CLASSES TO SET COLORS TO COMPONENTS ----- */

/* ----- SETTING OF CUSTOM STYLE IN 'H' TAGS ----- */
h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-h1);
}

h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
}

h3 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
}

h4 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-h4);
}

h5 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-h5);
}

h6 {
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-h6);
}

@media (max-width: 768px) {
    h1 {
        font-size: var(--font-size-h1-mobile);
        font-weight: var(--font-weight-h1-mobile);
    }

    h2 {
        font-size: var(--font-size-h2-mobile);
        font-weight: var(--font-weight-h2-mobile);
    }

    h3 {
        font-size: var(--font-size-h3-mobile);
        font-weight: var(--font-weight-h3-mobile);
    }

    h4 {
        font-size: var(--font-size-h4-mobile);
        font-weight: var(--font-weight-h4-mobile);
    }

    h5 {
        font-size: var(--font-size-h5-mobile);
        font-weight: var(--font-weight-h5-mobile);
    }

    h6 {
        font-size: var(--font-size-h6-mobile);
        font-weight: var(--font-weight-h6-mobile);
    }
}

/* ----- END OF SETTING OF CUSTOM STYLE IN 'H' TAGS ----- */

/* ----- GOOGLE MATERIAL ICONS SETTINGS ----- */
.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 600,
        'GRAD' 0,
        'opsz' 48
}

/* ----- END OF GOOGLE MATERIAL ICONS SETTINGS ----- */


/* ----- CUSTOMIZE SCROLLBAR OVER THE APP ----- */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--grey5);
    border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--grey3);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field-wrapper {
    padding: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.box_container {
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--seconday-color);
    flex-direction: column;
}

.box_header{
    width: 100%;
    align-items: center; 
    justify-content: space-between; 
    flex-direction: row; 
    font-size: 20px;
    padding: 10px;
    display: flex;
}

.box_title_22{
    font-family: 'Poppins' !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    color: var(--grey_2) !important;
}

.box_title_18{
    font-size: 18px; 
    font-weight: 500;
    color: var(--grey_2) !important;
}

.box_title_14{
    font-size: 14px; 
    font-weight: 600;
    color: var(--grey_2) !important;
}

.border_top_grey3{
    border-top: 1px solid var(--grey3);
}

.border_top_grey5{
    border-top: 1px solid var(--grey5);
}

.border_bottom_grey3{
    border-bottom: 1px solid var(--grey3);
}

.border_bottom_grey5{
    border-bottom: 1px solid var(--grey5);
}

.cursor-pointer{
    cursor: pointer;
}

.position-relative{
    position: relative;
}

.position-absolute{
    position: absolute;
}

.position-fixed{
    position: fixed;
}

.error_span{
    position: absolute;
    left: 12px;
    font-size: 12px;;
}

/* Placeholder Label transition  */
.label_ {
    display: inline-block;
    transform: translate(0, -2.5rem);
    width: auto;
    background-color: var(--white);
    padding: 0 3px;
    margin-bottom: 0px;;
}

.label_ms {
    display: inline-block;
    transform: translate(0, -3.10rem);
    width: auto;
    background-color: var(--white);
    padding: 0 3px;
    margin-bottom: 0px;;
}

.input_{
    box-shadow: none !important;
    columns: var(--grey2);
}

@supports (not (-ms-ime-align:auto)) {
    .label_ {
    color: var(--grey4);
    transform: translate(0.5rem, -2rem);
    transition: all 0.2s ease-out;
    border-radius: 8px;
    line-height: 14px;
    }

    .input_:focus + .label_,
    .input_:not(:placeholder-shown) + .label_ {
    color: var(--grey3);
    transform: translate(0.5rem, -3.25rem);
    }

    .input_:focus + .label_ms,
    .input_:not(:placeholder-shown) + .label_ms {
    color: var(--grey3);
    transform: translate(0.5rem, -3.10rem);
    }

    .ta2.label_ {
        color: var(--grey4);
        transform: translate(0.5rem, -3.5rem);
        transition: all 0.2s ease-out;
    }
    
    .input_:focus + .ta2.label_,
    .input_:not(:placeholder-shown) + .ta2.label_ {
    color: var(--grey3);
    transform: translate(0.5rem, -4.75rem);
    }

    .ta3.label_ {
        color: var(--grey4);
        transform: translate(0.5rem, -5rem);
        transition: all 0.2s ease-out;
    }
    
    .input_:focus + .ta3.label_,
    .input_:not(:placeholder-shown) + .ta3.label_ {
    color: var(--grey3);
    transform: translate(0.5rem, -6.25rem);
    }

    .ta4.label_ {
        color: var(--grey4);
        transform: translate(0.5rem, -6.5rem);
        transition: all 0.2s ease-out;
    }
    
    .input_:focus + .ta4.label_,
    .input_:not(:placeholder-shown) + .ta4.label_ {
    color: var(--grey3);
    transform: translate(0.5rem, -7.75rem);
    }

    .ta5.label_ {
        color: var(--grey4);
        transform: translate(0.5rem, -8rem);
        transition: all 0.2s ease-out;
    }
    
    .input_:focus + .ta5.label_,
    .input_:not(:placeholder-shown) + .ta5.label_ {
    color: var(--grey3);
    transform: translate(0.5rem, -9.25rem);
    }

    .ta6.label_ {
        color: var(--grey4);
        transform: translate(0.5rem, -9.5rem);
        transition: all 0.2s ease-out;
    }
    
    .input_:focus + .ta6.label_,
    .input_:not(:placeholder-shown) + .ta6.label_ {
    color: var(--grey3);
    transform: translate(0.5rem, -10.75rem);
    }
}

/* Remove autofill cover */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
    transition-property: background-color, color;
}

.label_13_grey3
{
    font-size: 13px;
    font-weight: 400;
    color: var(--grey3);
    line-height: 28px;
}

.label_13_grey2
{
    font-size: 13px;
    font-weight: 500;
    color: var(--grey2);
    line-height: 28px;
}

.p-lr-10{
    padding-left:10px !important;
    padding-right:10px !important;
}


/* Views start*/

.view_box_container {
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: var(--seconday-color);
    padding: 10px 10px;

}
.view_box_container .view_block ul {
    padding-left: 0px;
    display: flex;
    align-items: center;
    margin: 0;
}
.view_box_container .view_block ul li {
    display: inline-block;
    list-style: none;
    padding-right: 20px;
}
.view_box_container .view_block ul li img {
    width: 75px;
}
.view_box_container .view_block ul li p {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.view_box_container .view_block label {
    width: 200px;
}
.view_box_container .view_block .status {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    background: #54B66F;
    border-radius: 4px;
    padding: 2px 8px;
}
.view_box_container .view_buttons {
    text-align: right;
    padding-top: 25px;
}
.view_box_container .view_block ul li span {
    background: #54B66F1F;
    padding: 2px 8px;
    border-radius: 4px;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--green);
    position: relative;
    top: -18px;
}
.view_box_container .save_button {
    padding: 7px 12px;
    border-radius: 4px;
    border: 2px solid #10A0C1;
    background: #10A0C1;
    color: var(--white);
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
}
.view_box_container .edit_button {
    padding: 7px 12px;
    border-radius: 4px;
    border: 2px solid #10A0C1;
    background-color: #fff;
    color: var(--main);
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    margin-right: 20px;
}

.tab_div {
    background: #E7E7E94D;
    border-radius: 0px 0px 8px 8px;
    padding: 0px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.tab_div.bottom-shadow{
    box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.tab_div ul {
    padding: 0px !important;
    margin: 0;
}
.list-item-container-view .list-item {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey3);
    list-style: none;
    cursor: pointer;
    display: inline-block;
    padding: 15px 0px 15px 0px;
    margin-right: 40px;
}
.list-item-container-view .list-item.active {
    opacity: 1;
    color: var(--grey2);
    border-bottom: 3px solid #FFAD2A;
    text-align: center;
}
.tab_box_container {
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: var(--seconday-color);
    padding: 10px 0px 0px 0px;
    margin-top: 35px;
}
.tab_box_container ul:not(.categories) {
    padding-left: 0px;
    margin-bottom: 0px;
}
.tab_box_container ul:not(.categories) li {
    list-style: none;
    padding: 10px 20px;
    border-bottom: 1px solid #E7E7E9;
    /* margin-bottom: 10px; */
}
.tab_box_container ul:not(.categories) li h4 {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #47475C;
}

.tab_box_container .label_controls{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    line-height: 17px;
}

.tab_box_container .img_preview{
    height: 50px;
    width: 50px;
    border-radius: 5px;
  }

  /* views end */

  /* Create Edit start */
  .list-item-container .list-item {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--grey4);
    list-style: none;
    cursor: pointer;
}
.list-item-container .list-item img {
    width: 30px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .list-item-container .list-item.active {
    /* border: 1px solid var(--main);
    border-radius: 8px;  
    height: 40px;     */
    text-align: center;
    opacity: 1;
    color: var(--grey2);
  }

  .right-12{
    right: 12px;
    left: auto;
  }
  
  .img_preview{
    height: 100px;
    width: 100px;
    border-radius: 5px;
  }
 
  .list-item-container .list-item.active , .list-item-container .list-item.active:hover{
    /* color: var(--white) !important; */
  }
  /* .tabs_ul {
    padding: 10px 0px 10px 0px;
  } */
  .list-item-container .list-item ,.list-item-container .list-item:hover{
    /* color: var(--main) !important; */
  }
  /* Create Edit End */


  /* Dashboard css Start */
  .view_box_container {
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: var(--seconday-color);
    padding: 10px 10px;
}

.dashboard_block {
    box-shadow: 0px 0px 4px 0px #0000001A;
    background: #FFFFFF;
    border-radius: 8px;
}

.dashboard_block span.material-symbols-outlined
{
    vertical-align: middle;
    font-size: 20px;
    color: var(--grey4);
    cursor: pointer;
}

.dashboard_block .dashboard_block_header
{
    padding: 12px 12px 12px 12px;
}

.dashboard_block .dashboard_block_body
{
    
}

.dashboard_block .tabs_div ul, .tabs_content .tabs_div1 ul {
    background: #E7E7E94D;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 15px 0px 15px 0px;
}
.dashboard_block .tabs_div .list-item,.tabs_content .tabs_div1 .list-item {
    cursor: pointer;
    display: inline;
    margin-right: 20px;
    padding: 13px 10px 13px 10px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey3);
}

.dashboard_block .tabs_div .list-item:first-child
{
    border-bottom-left-radius: 8px;
}

.dashboard_block .tabs_div .list-item img,.tabs_content .tabs_div1 .list-item img {
    width: 30px;
    margin-right: 5px;
    vertical-align: middle;
}
.dashboard_block .tabs_div .list-item.active, .tabs_content .tabs_div1 .list-item.active {
    border-bottom: 3px solid #FFAD2A;
    opacity: 1;
}

.tabs_content .title_div h6 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey2);
}

.tabs_content .filter_div {
    box-shadow: 0px 0px 8px 0px #0000001A;
    background: #FFFFFF;
    padding: 4px;
    border-radius: 8px;
    float: right;
}
.tabs_content .filter_div ul {
    padding-left: 0px;
    margin-bottom: 0px;
}
.tabs_content .filter_div ul li {
    list-style: none;
    display: inline;
}
.tabs_content .dropbtn {
    background-color: var(--grey6);
    color: var(--grey2);
    padding: 10px;
    font-size: 7px 12px;
    border: none;
    cursor: pointer;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    border-radius: 4px;
}
  
.tabs_content .dropdown {
    position: relative;
    display: inline-block;
}

.tabs_content .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.tabs_content .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.tabs_content .dropdown-content a:hover {
    background-color: #f1f1f1
}

.tabs_content .dropdown:hover .dropdown-content {
    display: block;
}

.tabs_content .tabs_div1 ul {
    padding-left: 0px;
}
.tabs_content .tabs_div1 ul li {
    padding: 15px 20px 15px 8px !important;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px #0000001A;
    border-radius: 8px;
    border: 1px solid #fff;
}
.tabs_content .tabs_div2 ul li {
    padding: 15px 20px 15px 8px !important;
    margin-right: 10px !important;
}
.tabs_content .tabs_div1 ul li img {
    width: auto !important;    
}

.tabs_content .title_div h6 a {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--main);
    float: right;
    text-decoration: none;
}

.tabs_content .tab-pane{
    width: 100%;
}

.tabs_content .tab-pane .row:first-child{
    align-items: end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dashboard_block .dash_icon_img {
    float: right;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--green);
}
.dashboard_block p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey3);
    margin-bottom: 0;
}
.dashboard_block h4 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey2);
    margin-top: 15px;
}
.dashboard_block p a {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--main);
    text-decoration: underline;
}
.title_div1 h6 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey2);
}

.dashboard_block1 {
    box-shadow: 0px 0px 6px 0px #0000001A;
    background: #FFFFFF;
    padding: 5px 5px;
    border-radius: 8px;
}

.dashboard_block1 ul {
    margin-bottom: 0;
    padding-left: 0px;
    display: inline-flex;
    align-items: center;
}
.dashboard_block1 ul li {
    list-style: none;

}
.dashboard_block1 ul li p {
    margin-bottom: 0;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey3);
    padding-left: 10px;
}
.dashboard_block1 ul li p span {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--green);
}

/* 
.com_div {
    padding-right: 3px;
}
.com_div1 {
    padding-right: 3px;
    padding-left: 3px;
}
.com_div2 {
    padding-right: 3px;
} */
.commission_charts {
    box-shadow: 0px 0px 6px 0px #0000001A;
    padding: 12px 12px;
    border-radius: 8px;
    background-color: #fff;
}
.commission_charts ul {
    margin-bottom: 0;
    padding-left: 0px;
    display: inline-flex;
    align-items: center;
}
.commission_charts ul li {
    list-style: none;

}
.commission_charts ul li p {
    margin-bottom: 0;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey3);
    padding-left: 10px;
}
.commission_charts ul li p span {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--green);
}
.commission_charts .dash_icon_img {
    font-family: Poppins;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18px;
    letter-spacing: 0px;
    color: var(--grey4) !important;
    float: right !important;
}
.barchartsdiv canvas {
    position: relative;
    top: 75px;
}
.barchartsdiv1 canvas {
    position: relative;
    top: 15px !important;
}
.barchartsdiv4 canvas {
    position: relative;
    top: 15px !important;
}

.active1 {
    border-radius: 8px;
    border: 1px solid #FFAD2A !important;
    box-shadow: 0px 4px 10px 0px #0000001A;
}
#comprehensive3 .commission_charts {
    margin-top: 31px !important;
}

.leads_div ul {
    padding-left: 0px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.leads_div ul li {
    list-style: none;
    
}
.leads_div ul li p {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.leads_div ul li:nth-child(1) img {
    width: 40px;
}

.customers_div {
    box-shadow: 0px 0px 6px 0px #0000001A;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 1px;;
}
.customers_div ul {
    padding: 7px 7px 0;
    display: inline-block;
    margin-bottom: 0px;
}
.customers_div ul li {
    list-style: none;
    float: left;
    padding-right: 10px;
}
.customers_div ul li img {
    width: 40px;
}
.customers_div ul li p {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.customers_div ul li p span {
    float: right;
    position: absolute;
    right: 6%;
}
.customers_div ul li p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

}
.customers_div ul li p b {
    font-size: 14px;
}
.customers_block {
    background: #FFAD2A1A;
    padding: 0 5px;
    border-radius: 8px;
}
.customers_block ul{
    display: flex;
    justify-content: space-between;
    padding: 7px 7px;
}

.customers_block ul li {
    padding-right: 8px;
}
.agents_div .col-lg-6, .agents_div .col-md-6, .agents_div .col-sm-6 {
    padding-right: 4px;
}
.reminders_div {
    box-shadow: 0px 0px 6px 0px #0000001A;
    border-radius: 16px;
    padding: 16px;
    background: #FFFFFF;
}
.reminders_div table td {
    font-family: Poppins;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

}
.company_div {
    box-shadow: 0px 0px 6px 0px #0000001A;
    background: #FFFFFF;
    padding: 12px 0px 0px 0px;
    border-radius: 8px;
}



.recently_added_div table tbody tr td .text-grey2{
  font-size: 14px;
}

.recently_added_div table tbody tr td .text-grey3{
    font-size: 13px;
  }

.recently_added_div table tbody tr td:first-child{
    width:50px
}
.recently_added_div table tbody tr td:first-child img{
    width:44px
}

.recently_added_div table tbody tr td:last-child{
    width:20px
}

.total_container{
    min-height: 90px;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-end;
    padding: 6px !important;
    cursor: pointer;
}

.total_container .value_{
    color: var(--grey1);
    font-size: 18px;
}

.total_container .value_label_{
    color: var(--grey3);
    font-size: 13px;
}

/* .dashboard_block2 table thead tr th {
    background: #848893;
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

}
.dashboard_block2 table tbody tr td {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--grey2);
}
.dashboard_block2 h5 a {
    float: right;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--main);
    text-decoration: underline;
    margin-right: 20px;
}  */
/* .header_part {
    border-bottom: 1px solid #E7E7E9;
}
.recently_added_div {
    
} */
  /* Dashboard css End */



  /* Menu css Start */
  .menu_item {
    padding: 0px 5px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
    cursor: pointer;
    user-select: none;
}

.menu-icon{
    width: 40px;
    height: 36px;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.active-menu-icon{
    background-color: var(--main);
    color: var(--white);
    opacity: 1;
}

.menu-label {
    color: var(--grey1); 
    pointer-events: none;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 36px;
    display: flex;
    align-items: center;
    opacity: 0.3;    
    padding-left: 5px;
    margin-bottom: 0px !important;
}

.active-menu-label{    
    opacity: 1;
    background-color: var(--grey5);
}

.opacity-1{
    opacity: 1 !important;
}

/* Menu css End */


/* Font css Start */
.font-10{
    font-size: 10px !important;
}

.font-11{
    font-size: 11px !important;
}

.font-12{
    font-size: 12px !important;
}

.font-13{
    font-size: 13px !important;
}

.font-14{
    font-size: 14px !important;
}

.font-15{
    font-size: 15px !important;
}

.font-16{
    font-size: 16px !important;
}

.font-17{
    font-size: 17px !important;
}

.font-18{
    font-size: 18px !important;
}

.font-19{
    font-size: 19px !important;
}

.font-20{
    font-size: 20px !important;
}

/* Font css Start */


.rounded1{
    border-radius: var(--size-8) !important;
  }
  
  .rounded-bottom1 {
    border-bottom-right-radius: var(--size-8) !important;
    border-bottom-left-radius: var(--size-8) !important;
  }
  
  .rounded-top1 {
    border-top-right-radius: var(--size-8) !important;
    border-top-left-radius: var(--size-8) !important;
  }
  
  .rounded-left1 {
    border-bottom-left-radius: var(--size-8) !important;
    border-top-left-radius: var(--size-8) !important;
  }
  
  .rounded-right1 {
    border-bottom-right-radius: var(--size-8) !important;
    border-top-right-radius: var(--size-8) !important;
  }
  
  .rounded-bottom1-0 {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  
  .rounded-top1-0 {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  
  .rounded-left1-0 {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  
  .rounded-right1-0 {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .form-control.input_.tp:read-only{
    background-color: transparent !important;
    cursor: not-allowed;
}

.text-uppercase{
    text-transform: uppercase !important;
}

.text-lowercase{
    text-transform: lowercase !important;
}

.required:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-left: 0.25rem;
    font-weight: 600;
  }

.bg-image{
    background-repeat:no-repeat; 
    background-size:105%;
    background-position:center center;
}  

.mh-102{
    min-height: 102px !important;
    max-height: 102px !important;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup2{
  padding: 0px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.overlay.left .popup2,
.overlay.right .popup2 {
	position: fixed;
	margin: auto;
	/* width: 40%; */
	height: 100%;
  right: 0 !important;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
  /* padding: 4px 0px !important; */
  overflow-x: auto;
}

.modal.left .modal-content,
.modal.right .modal-content {
	height: 100%;
	overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
	padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .popup2 {
	left: -320px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .popup2 {
	left: 0;
}

/*Right*/
.modal.right.fade .popup2 {
	right: -320px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .popup2 {
	right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
	border-radius: 0;
	border: none;
}

.popup2 > .modal-header {
    border-bottom-color: #eeeeee;
    background-color: var(--grey4);
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.popup2 > .modal-header > h3{
  margin-bottom: 0px !important;
}

.popup2 > .modal-header.tooltip_{
  padding: 10px 20px;
}

.popup2 > .modal-body{
  padding: 20px 20px 20px 20px;
  text-align: left;
}

.popup2 > .modal-header .close {
  opacity: 1 !important;
  margin: -1rem -1.3rem -1rem auto !important;
}

.popup2 > .modal-header > button.close::after {
  content: url('/assets/svgIcon/close-secondary.svg');
 }

 
.tab-list-item{
    padding: 10px 5px;
    font-size: 15px;
    list-style: none;
}
.tab-list-item.active {
    border: 1px solid var(--main);
    border-radius: 8px;  
    /* height: 40px; */
    background-color: var(--main);
    text-align: center;
}

.tab-list-item.active ,.tab-list-item.active:hover{
    color: var(--white) !important;
}

.tab-list-item ,.tab-list-item:hover{
    color: var(--main) !important;
}


.collapsible-panel {
    border: 1px solid unset;
  }
  
  .panel-header {
    background-color: var(--grey5);
    padding: 8px 12px;
    cursor: pointer;
    user-select: none;
    display: flex;
    font-size: 17px;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
  }
  
  .collapse-icon {
    font-size: 20px;
  }
  .panel-content {
    padding: 10px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }
  
  .expanded {
    max-height: 500px; /* Adjust this value based on your content */
    transition: all .5s;
  }
  
  .collapsed{
    padding: 4px 10px !important;
    transition: all .5s;
  }

  .overflow-initial{
    overflow: initial !important;
}

.form-select:focus,.btn:focus,input[type=checkbox], .form-control:focus {
    box-shadow: none !important;
  }

  .h-100vh{
    height: 100vh;
  }
  .bg-1 {
    position: absolute;
    left: 0;
    top: -30%;
  }
  .bg-2 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .bg-3 {
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
  }

  .tb-secondary{
    accent-color: var(--main1);
  }

  .scale-1_6{
    scale: 1.6;
  }

  /* input:read-only, select:read-only {
    cursor: not-allowed !important;
  } */

  .t-n12{
    top: -12px;
  }

  .mh-350-jcsb{
    min-height: 350px;
    justify-content: space-between !important;
  }